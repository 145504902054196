import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Navbar } from "./layouts/NavbarAndFooter/Navbar";
import { Footer } from "./layouts/NavbarAndFooter/Footer";
import { HomePage } from "./layouts/HomePage/HomePage";

export const App = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className={"flex-grow-1"}>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/home"} element={<HomePage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};
